/* Footer 页面底部 */
import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;


export default function FooterComponent() {
  return (
    <Footer>
      © {new Date().getFullYear() + " "}
    </Footer>
  );
}
