import { getProfile } from '@utils/profile';
const profile = getProfile()

export const types = {
  LOGIN_REQUEST: 'LOGIN/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN/LOGIN_FAILURE',
  LOGOUT: 'LOGIN/LOGOUT',
}

export const initialState = {
  profile,
  isLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return { ...state, isLoading: true }

    case types.LOGIN_SUCCESS:
      return { ...state, isLoading: false, profile: action.profile }

    case types.LOGIN_FAILURE:
      return { ...state, isLoading: false }

    case types.LOGOUT:
      return { ...state, profile: {} }

    default:
      return state
  }
}

export const actions = {
  login: (payload) => ({
    type: types.LOGIN_REQUEST,
    payload
  }),
  logout: () => ({
    type: types.LOGOUT
  }),
}