import React, { useCallback, Suspense } from "react";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import BasicLayout from "../layouts/BasicLayout";
import LoginLayout from "../layouts/LoginLayout";
import Loading from "@/components/Loading";


const mapStateToProps = ({ login: { profile = {} } }) => {
  return {
    profile,
  };
};

export default connect(mapStateToProps)(function (props) {
  const { profile: { token } } = props
  const isLogin = Boolean(token);

  const onBasicEnter = useCallback((Component, props) => {
    if (isLogin) {
      return <Component {...props} />;
    }
    return <Redirect to="/login" />;
  }, [isLogin]);

  const onLoginEnter = useCallback((Component, props) => {
    if (isLogin) {
      return <Redirect to="/" />;
    }
    return <Component {...props} />;
  }, [isLogin]);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/login" render={(props) => onLoginEnter(LoginLayout, props)} />
          <Route path="/" render={(props) => onBasicEnter(BasicLayout, props)} />
        </Switch>
      </Suspense>
    </Router>
  );
});