import React, { useState, lazy, useCallback } from "react";
import { connect } from "react-redux";
import { Layout } from 'antd';
import { withRouter, Route, Redirect, Switch } from "react-router-dom";
import { actions as loginActions } from '@ducks/login';
import './BasicLayout.css'

import Header from "@/components/Header";
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import Bread from "@/components/Bread";

const { Content } = Layout;

const [NotFound, NoPower, ServerError, Home, Member, Order, Activity] = [
  () => import(/* webpackChunkName: "404" */ "@/pages/404"),
  () => import(/* webpackChunkName: "401" */ "@/pages/403"),
  () => import(/* webpackChunkName: "500" */ "@/pages/500"),
  () => import(/* webpackChunkName: "Home" */ "@/pages/Home"),
  () => import(/* webpackChunkName: "Member" */ "@/pages/Member"),
  () => import(/* webpackChunkName: "Order" */ "@/pages/Order"),
  () => import(/* webpackChunkName: "Activity" */ "@/pages/Activity"),
].map((item) => {
  return lazy(item);
});

const routers = [
  { url: '/home', component: Home },
  { url: '/member', component: Member },
  { url: '/order', component: Order },
  { url: '/activity', component: Activity },
]

const BasicLayout = (props) => {
  const { location, history, onLogout, userinfo } = props
  const [collapsed, setCollapsed] = useState(false);

  const onEnter = useCallback(
    (Component, props) => {
      // 可处理是否有权限
      if (true) {
        return <Component {...props} />;
      }
      // return <Redirect to="/nopower" />;
    }, []);

  return (
    <Layout className="page-basic" hasSider>
      <Menu
        menus={userinfo.menus}
        collapsed={collapsed}
        location={location}
        history={history}
      />

      <Layout>
        <Header
          collapsed={collapsed}
          userinfo={userinfo}
          onToggle={() => setCollapsed(!collapsed)}
          onLogout={onLogout}
        />
        {/* <Bread menus={userinfo.menus} location={location} /> */}
        <Content className="content">
          <Switch>
            <Redirect exact from="/" to="/home" />
            {
              routers.map((router) => {
                return <Route exact path={router.url} key={router.url} render={(props) => onEnter(router.component, props)} />
              })
            }
            <Route exact path="/nopower" component={NoPower} />
            <Route component={NotFound} />
          </Switch>
        </Content>
        {/* <Footer /> */}
      </Layout>
    </Layout>
  );
}

const mapStateToProps = ({ login: { profile } }) => {
  return {
    userinfo: profile
  };
};


const mapDispatchToProps = {
  onLogout: loginActions.logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BasicLayout));
