import { takeEvery, all, call, put, select } from 'redux-saga/effects';
import { types as loginTypes } from '@ducks/login';
import { login } from '@services/api';
import { setProfile, clearProfile } from '@utils/profile';
import { push } from 'react-router-redux';

const menus = [
  { title: '首页', url: '/home', children: [] },
  { title: '报名管理', url: '/order', children: [] },
  { title: '用户管理', url: '/member', children: [] },
  { title: '活动管理', url: '/activity', children: [] },
  // {
  //   title: '菜单', url: '/caidan', children: [
  //     { title: '标题2-1', url: '/caidan/user', parentTiele: '菜单' },
  //     { title: '标题2-2', url: '/caidan/member', parentTiele: '菜单' },
  //   ]
  // },
]

export default function* loginSaga() {
  yield all([
    takeEvery(loginTypes.LOGIN_REQUEST, function* ({ payload: { username, password } }) {
      const result = yield call(login, {
        username,
        password
      })
      if (result) {
        const { token, username } = result
        const profile = {
          token,
          username,
          menus
        }
        yield put({
          type: loginTypes.LOGIN_SUCCESS, profile
        })
      } else {
        yield put({ type: loginTypes.LOGIN_FAILURE })
      }
    }),
    takeEvery(loginTypes.LOGIN_SUCCESS, function* () {
      const { login: { profile } } = yield select()
      setProfile(profile)
      yield put(push('/'))
    }),
    takeEvery(loginTypes.LOGOUT, function* () {
      clearProfile()
      yield put(push('/login'))
    }),
  ]);
}
