export const getProfile = () => {
  try {
    const profile = sessionStorage.getItem("profile");
    if (!profile) {
      return {}
    }
    const decodedProfile = JSON.parse(profile);
    return decodedProfile;
  } catch (err) {
    return {}
  }
};

export const setProfile = (profile = {}) => {
  sessionStorage.setItem("profile", JSON.stringify(profile));
};

export const clearProfile = () => {
  sessionStorage.clear("profile");
};
