import React, { lazy } from "react";
import { Layout } from "antd";
import { Route, Redirect, Switch } from "react-router-dom";
import "./LoginLayout.css";

const [NotFound, Login] = [
  () => import(/* webpackChunkName: "404" */ "@/pages/404"),
  () => import(/* webpackChunkName: "Login" */ "@/pages/Login")
].map((item) => {
  return lazy(item);
});

const LoginLayout = () => {
  return (
    <Layout className="page-login">
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
}

export default LoginLayout