
import axios from 'axios'
import { message } from 'antd';
import { getProfile, clearProfile } from '@utils/profile';

const service = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
  withCredentials: false,
  timeout: 5000
});

service.interceptors.request.use(
  (config) => {
    const { token } = getProfile()
    if (token) config.headers.authorization = token
    return config
  }, (error) => {
    return Promise.reject(error)
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.errors) {
        switch (response.data.errors) {
          case 401:
            message.error("身份验证失败，请关闭重新进入。");
            clearProfile()
            window.location.href = "/";
            break;
          case 403:
            message.error("登录过期，请关闭重新进入。");
            clearProfile()
            window.location.href = "/";
            break;
          default:
            message.error(response.data.message);
        }
      } else {
        return Promise.resolve(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  }, (error) => {
    return Promise.resolve(null);
  }
);

export default service;
