import services from '@services';

export const upload = process.env.REACT_APP_AXIOS_BASE_URL + '/upload'

export const login = (data = {}) => {
  return services.post('/v1/cms/login', data)
}

export const getOrder = (params = {}) => {
  return services.get(`/v1/cms/order`, {
    params
  })
}
export const getMember = (params = {}) => {
  return services.get(`/v1/cms/member`, {
    params
  })
}
export const getActivity = (params = {}) => {
  return services.get(`/v1/cms/activity`, {
    params
  })
}
export const updateActivityAttach = (data = {}) => {
  return services.put(`/v1/cms/activity`, data)
}
