import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import './index.css'

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const menusDom = (menus) => {
  return menus.map((menu) => {
    if (menu.children && menu.children.length > 0) {
      return (
        <SubMenu
          key={menu.url}
          title={menu.title}
        >
          {menusDom(menu.children)}
        </SubMenu>
      );
    } else {
      return (
        <Item key={menu.url}>
          {menu.title}
        </Item>
      );
    }
  });
};

export default function MenuComponent(props) {
  const { location: { pathname }, history, collapsed, menus } = props
  const [chosedKey, setChosedKey] = useState([]); // 当前选中
  const [openKeys, setOpenKeys] = useState([]); // 当前需要被展开的项

  // 当页面路由跳转时，即location发生改变，则更新选中项
  useEffect(() => {
    const paths = pathname.split("/").filter((item) => !!item);
    setChosedKey([pathname]);
    setOpenKeys(paths.map((item) => `/${item}`));
  }, [pathname]);

  const onSelect = useCallback((e) => {
    history.push(e.key);
  }, [history]);

  return (
    <Sider
      width={256}
      className="sider"
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className={collapsed ? "menuLogo hide" : "menuLogo"}>
        <Link to="/">
          <div>GBC</div>
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={chosedKey}
        {...(collapsed ? {} : { openKeys })}
        onOpenChange={(keys) => { setOpenKeys(keys) }}
        onSelect={onSelect}
      >
        {
          useMemo(() => {
            return menusDom(menus);
          }, [menus])
        }
      </Menu>
    </Sider>
  );
}
